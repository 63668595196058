import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import ClearIcon from "@material-ui/icons/Clear"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { fontSize } from "@material-ui/system"

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "0px 15px 15px",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "bold",
  },
  underlinedButton: {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "unset",
    },
    fontSize: "14px",
    marginRight: "10px",
    padding: "0px",
  },
  noRecords: {
    padding: "14px",
    fontSize: "16px",
    textAlign: "center",
  },
  gridBox: {
    border: "1px solid #ccc",
    height: "450px",
    borderRadius: "5px",
  },
  pagination: {
    textAlign: "end",
    padding: "10px",
    fontSize: "14px",
  },
  textField: {
    width: "300px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      padding: "5px",
      height: "42px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--primaryColor)",
    },
  },
  checkboxRoot: {
    "& .MuiSvgIcon-root": {
      fontSize: "larger",
    },
  },
}))

function MultiCheckBoxWithSearchKit(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    handleSearchField,
    searchFieldValue,
    handleClearSearch,
    allItems = [],
    selectedIds = [],
    setAllItems,
    itemsObj = {},
    loading,
    selectAllState = false,
    setSelectAllState,
    containerRef,
    totalDocs = "",
    fetchItems = () => {},
    isFileUploaded = true,
    heading = "",
    selectedCount = "",
    recordsText = "",
    handleSingleSelectItem = () => {},
    id,
    handleClearSelectedItems = () => {},
    handleSelectAllChange = () => {},
    hasMoreRecords = false,
    name = "",
    selectedTotalDocs="",
  } = props || {}

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop
      const scrollHeight = containerRef.current.scrollHeight
      const clientHeight = containerRef.current.clientHeight

      // if (scrollHeight - position <= 1 && !loading && itemsObj?.hasMore) {
      //   fetchItems()
      // }

      if ((scrollTop + clientHeight >= scrollHeight - 10) && !loading) {
        fetchItems()
      }
    }
  }
  return (
    <>
      <Grid item>
        <div className={classes.header}>
          {`${heading} `}
          {`(`}
          <span style={{ color: "var(--primaryColor)" }}>{`${
            selectedCount || 0
          }`}</span>
          {`)`}
        </div>
      </Grid>
      <Grid
        item
        style={{
          position: "relative",
          borderRadius: "5px",
          marginBottom: "15px",
        }}
      >
        <TextField
          id="search-bar"
          className={classes.textField}
          variant="outlined"
          placeholder={`${t("Search")}...`}
          size="small"
          style={{ width: "100%" }}
          onChange={(e) => handleSearchField(e)}
          defaultValue={searchFieldValue?.replace(/(\s{2,})/g, " ")}
          value={searchFieldValue?.replace(/(\s{2,})/g, " ")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch} edge="end">
                  {searchFieldValue && <ClearIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item className={classes.gridBox}>
        {isFileUploaded ? (
          totalDocs === 0 ? (
            <div className={classes.noRecords}>{t("No Records Found")}</div>
          ) : (
            <>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "42px",
                }}
              >
                <FormControlLabel
                  style={{ padding: "2px" }}
                  label={t("Select All")}
                  control={
                    <Tooltip
                      placement="right-start"
                      title={`${selectAllState ? t("Unselect All") :  t("Select All")} ${selectedTotalDocs} ${name}`}
                    >
                      <Checkbox
                        style={{ color: "var(--primaryColor)" }}
                        className={classes.checkboxRoot}
                        name="select All"
                        checked={selectAllState}
                        onChange={handleSelectAllChange}
                      />
                    </Tooltip>
                  }
                />

                <Button
                  className={classes.underlinedButton}
                  onClick={handleClearSelectedItems}
                >
                  Clear
                </Button>
              </div>
              <div
                ref={containerRef}
                onScroll={() => handleScroll()}
                style={{ height: "400px", overflowY: "auto" }}
              >
                {allItems.map((item) => (
                  <div
                    key={item.id}
                    style={{ padding: "0px 10px", height: "42px" }}
                  >
                    <FormControlLabel
                      style={{ padding: "2px" }}
                      label={item.name}
                      control={
                        <Checkbox
                          style={{ color: "var(--primaryColor)" }}
                          className={classes.checkboxRoot}
                          name={item.name}
                          checked={
                            _.some(
                              selectedIds,
                              (object) => object?.[id] === item.id
                            ) || false
                          }
                          onChange={(e) => handleSingleSelectItem(e, item)}
                        />
                      }
                    />
                  </div>
                ))}
                {loading && hasMoreRecords && (itemsObj?.type === "scroll" || itemsObj?.type === "init") &&(
                  <p style={{ fontSize: "14px", padding: "10px" }}>
                    {t("Loading ...")}
                  </p>
                )}
              </div>
            </>
          )
        ) : (
          <div className={classes.noRecords}>{recordsText}</div>
        )}
      </Grid>
      <Grid item>
        <div className={classes.pagination}>{`Lists ${allItems?.length} of ${
          totalDocs || 0
        }`}</div>
      </Grid>
    </>
  )
}

export { MultiCheckBoxWithSearchKit }
export default MultiCheckBoxWithSearchKit
