import { Grid, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  displayList: {
    padding: "12px",
    border: "1px solid #bfbfc2",
    borderRadius: "5px",
    marginTop: "10px",
    cursor: "pointer",
    width: "100%",
    fontSize: "14px",
  },
  noRecords: {
    padding: "16px",
    fontSize: "16px",
    textAlign: "center",
  },
  pagination: {
    textAlign: "end",
    padding: "10px",
    fontSize: "16px",
  },
  Active:{
    border:"1px solid var(--primaryColor)",
    color: "var(--primaryColor)"
  }
}))

function ScrollBasedPagination(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    selectedItems,
    setSelectedItems,
    containerRef,
    totalDocsRef,
    allItems,
    component,
    setIsModelUpload
  } = props || {}

  const handleSelectItem = (item) => {
    setSelectedItems(item)
    if(component === "ModelVersion"){
      setIsModelUpload(true)
    }
  }

  return (
    <>
      <Grid item>
        {totalDocsRef?.current === 0 ? (
          <div className={classes.noRecords}>{t("No Records Found")}</div>
        ) : (
          <>
            <div
              ref={containerRef}
              style={{ height: "450px", overflowY: "auto",marginTop: "0px" }}
            >
              {allItems.map((item) => (
                <Button
                  key={item.id}
                  className={clsx(
                    classes.displayList,{[classes.Active]: item._id === selectedItems._id }
                  )}
                  style={{ padding: "10px" }}
                  onClick={() => handleSelectItem(item)}
                >
                  {item.name}
                </Button>
              ))}
            </div>
          </>
        )}
      </Grid>
    </>
  )
}

export { ScrollBasedPagination }
export default ScrollBasedPagination
