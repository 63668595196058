import React, { useState, useEffect } from "react"
import {
  Typography,
  Button,
  Dialog,
  Grid,
  Divider,
  makeStyles,
} from "@material-ui/core"
import commonStyles from "../../styles/Pages/common/styles"
import { withTheme } from "styled-components/macro"
import { useTranslation } from "react-i18next"

const defaultHandleCloseAndRedirect = ({
  setOpen,
  setOpenConfirmationDialog,
  setReDirect = () => {},
  handleCloseButtonTrigger,
}) => {
  setOpen(false)
  setOpenConfirmationDialog(false)
  setReDirect(true)
  handleCloseButtonTrigger = () => {}
}
const useStyles = makeStyles({
  ConfirmationDialog: {
    "@media(max-width:767px)": {
      minWidth: "140px",
      maxWidth: "240px",
      minHeight: "150px",
      maxHeight: "240px",
      borderRadius: "8px",
    },
    minWidth: "540px",
    maxWidth: "540px",
    minHeight: "150px",
    borderRadius: "8px",
  },
})
function ConfirmationDialog(props) {
  const {
    handleCloseAndRedirect = defaultHandleCloseAndRedirect,
    setOpenConfirmationDialog = () => {},
    openConfirmationDialog,
    handleCloseButtonTrigger = () => {},
    handleCustomCloseRedirect = () => {},
    btn1Text="NO",
    btn2Text="YES"
  } = props || {}
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const commonClasses = commonStyles()
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false)
    setOpenConfirmationDialog(false)
    handleCloseButtonTrigger()
  }

  useEffect(() => {
    setOpen(openConfirmationDialog)
  }, [openConfirmationDialog])

  return (
    <Dialog
      className="cls-dialog-kit"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid container direction="column" className={classes.ConfirmationDialog}>
        <Grid style={{ paddingInlineStart: "20px", paddingBlock: 12 }}>
          <Typography variant="h6" className="cls-dialog-header-kit">
            {props.textHeadingContent
              ? t(props.textHeadingContent)
              : t("Confirm")}
          </Typography>
        </Grid>
        <Divider />
        <Grid
          container
          justify="center"
          direction="column"
          style={{ paddingBlock: 24, paddingInline: 20 }}
        >
          {props.textContent ? (
            <>
              <Typography variant="body1">{t(props.textContent)}</Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" className="cls-common-text-head">
                {props.isdelete
                  ? t(
                      "Selected item(s) would be deleted, do you really want to continue?"
                    )
                  : null}
              </Typography>
              <Typography variant="body1" className="cls-common-text-head">
                {props.setCancel ? t("ConfirmDialogText1") : null}
              </Typography>
            </>
          )}
          {props.deleteContent ? (
            <>
              <Typography variant="body1" className="cls-common-text-head">
                {t(props.deleteContent)}
              </Typography>
            </>
          ) : null}

          {props.setLogout ? (
            <>
              <Typography variant="body1" className="cls-common-text-head">
                {t(props.setLogout)}
              </Typography>
            </>
          ) : null}
        </Grid>
        <Divider />
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ gap: 8, paddingBlock: 12, paddingInline: 16 }}
        >
          <Button
            variant="outlined"
            color="default"
            size="small"
            className={commonClasses.defaultOutlinedButton}
            onClick={handleClose}
          >
            {t(btn1Text)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={commonClasses.containedButton}
            onClick={() =>
              handleCustomCloseRedirect() ||
              handleCloseAndRedirect({ ...props, setOpen })
            }
          >
            {t(btn2Text)}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { ConfirmationDialog }
export default withTheme(ConfirmationDialog)
